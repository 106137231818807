import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { AppComponent } from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ToastrModule} from 'ngx-toastr';
import {FrontTokenInterceptor} from './_helpers/front-token.interceptor';
import {FrontErrorInterceptor} from './_helpers/front-error.interceptor';
import {CountdownModule} from 'ngx-countdown';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      autoDismiss: true,
      timeOut: 5000,
      preventDuplicates: true
    }),
    CountdownModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS, useClass: FrontTokenInterceptor, multi: true
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: FrontErrorInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
