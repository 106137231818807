import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import {isPlatformBrowser} from '@angular/common';

const headers = new HttpHeaders({
  'Content-Type':  'application/json'
});

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private isAuthenticated: boolean;

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) private platformId: any
  ) { }

  getFrontToken(): string {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('token')) {
        return JSON.parse(localStorage.getItem('token'));
      }
    }
  }

  getAdminToken(): string {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('token')) {
        return JSON.parse(localStorage.getItem('token'));
      }
    }
  }

  getUser(): string {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('currentUser')) {
        return JSON.parse(localStorage.getItem('currentUser'));
      }
    }
  }

  getUserRole(): string {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('currentUser')) {
        return JSON.parse(localStorage.getItem('currentUser')).role;
      }
    }
  }

  logout() {
    this.http.post(environment.apiUrl + '/clientusers/clientLogout', {});
    localStorage.clear();
    location.reload(true);
    return;
  }

  frontLogout() {
    this.http.post(environment.apiUrl + '/users/logout', {});
    localStorage.removeItem('currentUser');
    localStorage.removeItem('token');
    localStorage.removeItem('ttl');
    location.reload(true);
    return;
  }

  public authenticated(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('token') && localStorage.getItem('currentUser')) {
        return this.isAuthenticated = true;
      }
    }
  }

  public hasProfile(): boolean {
    if (isPlatformBrowser(this.platformId)) {
      if (JSON.parse(localStorage.getItem('currentUser')).role == 'creator') {
        return JSON.parse(localStorage.getItem('currentUser')).profile;
      } else {
        return true;
      }
    }
  }

  adminAuthenticated(): boolean {
    if (localStorage.getItem('token') && JSON.parse(localStorage.getItem('currentUser')).role == 'admin') {
      return this.isAuthenticated = true;
    }
  }

  adminLogin(data) {
    return this.http.post( environment.apiUrl + '/login', data, {headers})
      .pipe(map((res: any) => {
        if (res.response && res.response.token) {
          localStorage.setItem('currentUser', JSON.stringify(res.response.user));
          localStorage.setItem('token', JSON.stringify(res.response.token));
        }
        return res;
      }));
  }

  userLogin(data) {
    return this.http.post( environment.apiUrl + '/login', data, {headers})
      .pipe(map((res: any) => {
        if (res.response && res.response.token) {
          localStorage.setItem('currentUser', JSON.stringify(res.response.user));
          localStorage.setItem('token', JSON.stringify(res.response.token));
        }
        return res;
      }));
  }

  register(data) {
    return this.http.post( environment.apiUrl + '/register', data, {headers})
      .pipe(map((res: any) => {
        if (res.response && res.response.token) {
          localStorage.setItem('currentUser', JSON.stringify(res.response.user));
          localStorage.setItem('token', JSON.stringify(res.response.token));
        }
        return res;
      }));
  }

  recoverPassword(data) {
    const params = new HttpParams().set('email', data.email);
    return this.http.get(environment.apiUrl + '/forgot-password', {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  resendActivationEmail() {
    return this.http.get(environment.apiUrl + '/verify/resend', {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  activateAccount(data) {
    const params = new HttpParams().set('expires', data.expires).set('signature', data.signature);
    return this.http.get(environment.apiUrl + '/verify/' + data.id + '/' + data.hash, {params, headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

  changePassword(data) {
    return this.http.post( environment.apiUrl + '/reset-password', data, {headers})
      .pipe(map((res: any) => {
        return res;
      }));
  }

}


